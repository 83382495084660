<template>
  <div class="grey lighten-1" style="height: 100%">
    <div class="pa-3 d-flex align-center justify-space-between">
      
    </div>
    <v-form
      ref="forgotPasswordForm"
      class="grey lighten-1 d-flex justify-center align-center"
      @submit.prevent="passwordrecovery()"
    >
      <v-card align="start" width="500">
        <v-card-title class="text-h5 font-weight-medium">
          Definir senha
        </v-card-title>
        <v-card-text>
          <div class="font-weight-medium">
            Texto texto texto texto texto texto texto texto texto texto texto
            texto texto
          </div>
          <div class="font-weight-bold mb-1 mt-3">Definir sua senha</div>
          <v-text-field
            v-model="definePassword.password"
            label="Digite sua senha"
            solo
            class="elevation-5"
            hide-details="auto"
            :rules="[$rules.required]"
            :disabled="loadingSave"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          />
          <div class="font-weight-bold mb-1 mt-3">Confirme a sua senha</div>
          <v-text-field
            label="Digite novamente sua senha"
            solo
            class="elevation-5"
            hide-details="auto"
            :rules="[$rules.required]"
            :disabled="loadingSave"
            :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showRepeatPassword ? 'text' : 'password'"
            @click:append="showRepeatPassword = !showRepeatPassword"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green"
            dark
            large
            class="mt-5"
            type="submit"
            :loading="loadingSave"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "DefinePassword",

  data: () => ({
    loading: false,
    loadingSave: false,
    showPassword: false,
    showRepeatPassword: false,
    definePassword:{
      password: "",
    }
  }),
};
</script>